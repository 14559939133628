import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import CustomText from "../custom-text"
import { OPEN_ACCOUNT_LINK } from "../../utils/constants"


const SectionKnowDigitra = () => {
  const { i18n, t } = useTranslation()

  return (
    <section className="w-full">
      <div className="relative grid grid-cols-12 lg:py-[5.25rem] lg:pl-20 md:p-15 p-6 font-poppins xs:mt-[3rem] md:!h-auto">

        <div className="flex flex-col justify-center h-full grid-column-start-2 md:grid-column-start-0 col-span-12 md:col-span-5">
          <CustomText className="leading-10 lg:!text-4xl lg:!leading-12">
            {t("buyBtcEthAndMore")}
          </CustomText>
          <br />
          <CustomText className="lg:!text-2xl hyphens-auto md:hyphens-none add-break-line">
            {t("exchangeThatPaysToUse")}
          </CustomText>
          <br />
          <br />
          <form
            className="w-fit bg-purple-primary px-4 py-4 rounded-lg items-start"
            action={OPEN_ACCOUNT_LINK}
            target="_blank">
            <button className="flex flex-row" type="submit">
              <div className="flex flex-col">
                <text className="text-l font-poppins font-semibold">
                  {t("start")}
                </text>
              </div>
            </button>
          </form>
          <br />
          <CustomText className="font-normal text-sm lg:text-base text-dark-soft-white hyphens-auto md:hyphens-none add-break-line">
            {t("startNowEarnDgta")}
          </CustomText>
        </div>

        <div className="flex flex-col justify-center items-center grid-column-start-8 md:grid-column-start-0 col-span-12 md:col-span-4">
          <>
            {i18n.language === "pt" ? (
              <StaticImage src="../../images/app_pt.png" alt="Phone image" />
            ) : (
              <StaticImage src="../../images/app_en.png" alt="Phone image" />
            )}
          </>
        </div>

      </div>
    </section>
  )
}

export default SectionKnowDigitra
