import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import CarouselItem from "./lp-carousel"

const SectionTradersLeague = () => {
  const { i18n, t } = useTranslation()
  var link

  if (i18n.language == "pt") {
    link = "https://digitra.com/pt/article/Liga-dos-Traders/"
  } else {
    link = "https://digitra.com/en/article/traders-league/"
  }

  return (
    <CarouselItem
      image={<StaticImage src="../../images/tradersLeague.png" alt="Traders League" />}
      title={t("tradersLeague")}
      text={t("tradersLeagueText")}
      link={link}
      linkText={t("learnMore")}
    />
  )
}

export default SectionTradersLeague
