import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import CarouselItem from "./lp-carousel"

const SectionReferralProgram = () => {
  const { i18n, t } = useTranslation()
  var link

  if (i18n.language == "pt") {
    link = "https://digitra.com/pt/article/ganhe-tokens-dgta-programa-de-indicacoes/"
  } else {
    link = "https://digitra.com/en/article/earn-dgta-tokens-digitra-referral-program/"
  }

  return (
    <CarouselItem
      image={<StaticImage src="../../images/referral.png" alt="Referral Program" />}
      title={t("referralProgram")}
      text={t("referralProgramText")}
      link={link}
      linkText={t("learnMore")}
    />
  )
}

export default SectionReferralProgram
