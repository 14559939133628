import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import CarouselItem from "./lp-carousel"

const SectionLiquidityProvider = () => {
  const { i18n, t } = useTranslation()
  var link
  var secondaryLink

  if (i18n.language == "pt") {
    link = "https://digitra.com/pt/article/como-ganhar-renda-passiva-provedor-de-liquidez-digitra-com/"
    secondaryLink = "https://digitra-legal.s3.amazonaws.com/public/LitePaper_Provedor_Liquidez.pdf"
  } else {
    link = "https://digitra.com/en/article/how-to-earn-passive-income-liquidity-provider-digitra-com/"
    secondaryLink = "https://digitra-legal.s3.amazonaws.com/public/LitePaper_Provedor_Liquidez.pdf"
  }

  return (
    <CarouselItem
      image={<StaticImage src="../../images/liquidity.png" alt="Liquidity Provider" />}
      title={t("liquidityProvider")}
      text={t("liquidityProviderText")}
      link={link}
      linkText={t("learnMore")}
      secondaryLink={secondaryLink}
      secondaryLinkText={t("readLitePaper")}
    />
  )
}

export default SectionLiquidityProvider
