import React from "react"
import GradientText from "../../components/landing-page/gradient-text"
import CustomText from "../custom-text"

type CarouselProps = {
  image: any
  title: string
  text: string
  link?: string | null
  linkText?: string | null
  secondaryLink?: string | null
  secondaryLinkText?: string | null
}

const CarouselItem = ({
  image,
  title,
  text,
  link,
  linkText,
  secondaryLink,
  secondaryLinkText,
}: CarouselProps) => {
  return (
    <div className="grid grid-cols-12 rounded-3xl p-5 sm:p-10 lg:!py-10 lg:!px-20 carousel-fixed-height items-center backdrop-blur bg-gradient-to-br from-dark-gradient-purple to-gradient-black font-poppins border-2 border-gray-900">
      <div className="col-span-12 md:col-span-4 rounded-3xl flex justify-center items-center">{image}</div>

      <div className="lg:!hidden col-span-12 md:col-span-8 mt-4 md:mt-0 md:ml-10 text-center md:text-start">
        <GradientText className="text-[2.75rem] font-semibold ">
          {title}
        </GradientText>
      </div>

      <div className="mt-4 lg:mt-0 lg:ml-20 col-span-12 lg:col-span-8 flex flex-col justify-center">
        <div className="mb-6 hidden lg:!block">
          <GradientText className="text-5xl font-semibold ">
            {title}
          </GradientText>
        </div>

        <CustomText className="text-sm md:text-2xl lg:text-[28px] font-medium lg:leading-10 hyphens-auto md:hyphens-none add-break-line">{text}</CustomText>

        {linkText != null && (
          <a
            href={link ?? "#"}
            className="mt-4 text-sm lg:text-base text-green-neon underline font-medium"
            target="_blank"
          >
            {linkText}
          </a>
        )}

        {secondaryLinkText != null && (
          <a
            href={secondaryLink ?? "#"}
            className="mt-4 text-sm lg:text-base text-green-neon underline font-medium"
            target="_blank"
          >
            {secondaryLinkText}
          </a>
        )}
      </div>
    </div>
  )
}

export default CarouselItem
