import React from "react"
import GradientText from "./gradient-text"
import CustomText from "../custom-text"

type DgtaCardProps = {
  image: any
  title: string
  text: string
}

const DgtaCardItem = ({
  image,
  title,
  text,
}: DgtaCardProps) => {
  return (
    <div className="grid grid-cols-12 rounded-3xl p-2 md:p-3 lg:p-5 backdrop-blur bg-gradient-to-br dgta-fixed-height from-gray-800 to-black-800 font-poppins border-2 border-gray-900">
      <div className="mt-2 md:mt-3 ml-2 md:mt-3 col-span-7 flex flex-col justify-center">
        <div className="mb-6">
          <GradientText className="text-3xl font-semibold ">
            {title}
          </GradientText>
        </div>

        <div className="mb-6">
          <CustomText className="text-xl hyphens-auto add-break-line">
            {text}
          </CustomText>
        </div>

      </div>

      <div className="col-span-4 rounded-3xl flex justify-end items-center">{image}</div>
    </div >
  )
}

export default DgtaCardItem
