import React, { useEffect, useState } from "react"
import CustomText from "../custom-text"

const SectionTitle = ({ title, subtitle }) => {

    return (
        <section className="w-full">
            <div className="relative grid grid-cols-12 lg:pl-20 md:px-15 px-6 xs:mt-[3rem] font-poppins md:!h-auto">
                <div className="flex flex-col grid-column-start-2 md:grid-column-start-0 col-span-12 md:col-span-10">
                    <CustomText className="leading-10 lg:!text-5xl xs:text-[1.75rem] lg:!leading-13">
                        {title}
                    </CustomText>

                    {subtitle && (
                        <CustomText className="leading-10 lg:!text-2xl xs:text-[1.75rem] text-gray-400 mt-4">
                            {subtitle}
                        </CustomText>
                    )}
                </div>
            </div>
        </section>
    )
}

export default SectionTitle
