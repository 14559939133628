import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import CarouselItem from "./lp-carousel"

const SectionTradeToEarn = () => {
  const { i18n, t } = useTranslation()
  var link

  if (i18n.language == "pt") {
    link = "https://digitra.com/pt/article/como-ganhar-tokens-dgta-trade-to-earn/"

  } else {
    link = "https://digitra.com/en/article/how-to-earn-dgta-tokens-trade-to-earn/"
  }

  return (
    <CarouselItem
      image={<StaticImage src="../../images/tradeEarn.png" alt="Trade to Earn" />}
      title={t("tradeEarn")}
      text={t("tradeEarnText")}
      link={link}
      linkText={t("learnMore")}
    />
  )
}

export default SectionTradeToEarn
