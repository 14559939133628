import React from "react"
import { useTranslation } from "react-i18next"
import DgtaCardItem from "./lp-dgta-card"
import { StaticImage } from "gatsby-plugin-image"
import SectionTitle from "./section-title"

const SectionDGTA = () => {
  const { t } = useTranslation()
  return (
    [
      <SectionTitle title={t("tokenDgta")} subtitle={t("tokenDgtaText")} />,

      <section className="relative mb-[8rem] max-w-7xl px-5 mt-[4rem] mb-[4rem] overflow-hidden">


        <div className="grid grid-cols-1 md:grid-cols-2 col-span-12 md:col-span-2 gap-4">

          <DgtaCardItem
            image={<StaticImage src="../../images/blockchain.png" alt="Blockchain" />}
            title={t("blockchain")}
            text={t("blockchainText")}
          />

          <DgtaCardItem
            image={<StaticImage src="../../images/supply.png" alt="Supply" />}
            title={t("supply")}
            text={t("supplyText")}
          />

          <DgtaCardItem
            image={<StaticImage src="../../images/burn.png" alt="Burn" />}
            title={t("burnPolicy")}
            text={t("burnPolicyText")}
          />

          <DgtaCardItem
            image={<StaticImage src="../../images/listing.png" alt="Listing" />}
            title={t("listing")}
            text={t("listingText")}
          />

        </div>

      </section >
    ]
  )
}

export default SectionDGTA
